<template>
  <div class="login-component">
    <h1>Agrarbund</h1>
    <div class="image-container">
      <!-- Assuming the image is imported or linked directly -->
      <div class="image-frame">
        <img src="@/assets/barn_cineastic.png" alt="Login" />
      </div>
    </div>
    <a  href="/login" 
        class="login-button" 
        @click.prevent="login()">
          Login
    </a>

  </div>
</template>

<script setup>
  const login = async () => {
    const loginUrl = import.meta.env.VITE_LOGIN_URL || 'https://gehtnicht.de';
    if ('http://localhost:8000/login' === loginUrl) {
      alert('running this site AND Oauth on Localhost!')
    }
    window.location.href = loginUrl;
  }
</script>

    //const response = await axios.get("http://localhost:8000/login")
    //    .then(response => {console.log(response);})
    //}

    // HIER KÖNNTE ICH JETZT EINE MINUTE LANG alle 2 sekunden eine whoami abfrage machen
    // und dann wenn ich den user habe das login signalisieren

    // call whoami every 2 seconds

    //const interval = setInterval(() => {
    //  axios.get("http://localhost:8000/whoami")
    //    .then(response => {
    //      console.log(response);
    //      if (response.data.user) {
    //        clearInterval(interval);
    //        login();
    //      }
    //    })
    //}, 2000);
    


  /*
  const login = async () => {
    console.log('user clicked login button')
    try 
        {
          const response = await axios.get('http://localhost:8000/login', {
              // Your login credentials or other necessary data
          });
          if (response.status === 200) {
            // Handle successful login here, e.g., redirect to the dashboard
            alert('Login successful');
          }
        } catch (error) {
          // Handle error here, e.g., show error message to the user
          console.log('cathed error')
          console.error('Login failed', error);
        }


    //window.location.href = 'http://localhost:8000/login';

  */



<style scoped>
.login-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; /* Evenly distributes space above, between, and below the main items */
  height: 100vh;
  position: relative;
}

.image-container {
  flex-grow: 1; /* Allows the container to expand, taking up available space, and pushing the header and login button to their positions */
  width: 100%;
  max-height: 70%; /* Controls the maximum size of the image container */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the image vertically within the image container if needed */
}

.image-frame {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top; /* Adjusts positioning to show the top part of the image, can be tweaked as needed */
}

.login-button, h1 {
  flex-grow: 0; /* Prevents the header and login button from resizing */
  flex-shrink: 0; /* Ensures they maintain their size regardless of the container size */
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  font-size: x-large;
  padding: 10px 20px;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 20px; /* Adjusts the margin at the bottom of the login button */
}

h1 {
  font-weight: lighter;
  font-size: 4vw; /* Adjust this value as needed */
  color: whitesmoke !important;

}

/* Alternatively, use media queries for more specific control */
@media (max-width: 600px) {
  h1 {
    font-size: 24px; /* Fixed size for small devices */
  }
}

@media (min-width: 1800px) {
  h1 {
    font-size: 60px; /* Fixed size for very large devices */
  }
}

.login-button:hover {
  color: whitesmoke;
  border-color: var(--secondary-color);
}
</style>
