<template>
    <svg    class="my-svg"
            xmlns="http://www.w3.org/2000/svg" 
            width="0.62em" 
            height="1em" 
            viewBox="0 0 256 417">
	<path fill="#ffffff" d="m127.961 0l-2.795 9.5v275.668l2.795 2.79l127.962-75.638z" />
	<path fill="#8c8c8c" d="M127.962 0L0 212.32l127.962 75.639V154.158z" />
	<path fill="#ffffff" d="m127.961 312.187l-1.575 1.92v98.199l1.575 4.601l128.038-180.32z" />
	<path fill="#8c8c8c" d="M127.962 416.905v-104.72L0 236.585z" />
	<path fill="#8c8c8c" d="m127.961 287.958l127.96-75.637l-127.96-58.162z" />
	<path fill="#393939" d="m.001 212.321l127.96 75.637V154.159z" />
</svg>
  </template>
       
  <style scoped>
    .my-svg {
      width: 22px; /* Control size */
      height: 22px; /* Control size */
    }
  </style>
  


