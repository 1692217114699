<template>
    <div class="inventory-menu">
        <nav>
            <ul>
                <li><a href="#inventory" @click.prevent="emitClick('inventory')">Inventory</a></li>
                <li><a href="#admin" @click.prevent="emitClick('admin')">Admin</a></li>
                <li><a href="#documentation" @click.prevent="emitClick('documentation')">Documentation</a></li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
    // a list of events this component can emit .. this line is best practice  but not mandatory
    const emit = defineEmits(['navigationClicked']);

    // Method to call when a navigation item is clicked
    function emitClick(navItem) {
        // Emit the 'navigationClicked' event with the navItem as payload
        emit('navigationClicked', navItem);
    }
</script>



<style scoped>
  
  .inventory h2 {
        color: var(--primary-color);
        font-weight: 400; /* Ensuring headings are slightly bolder than body text */
    }

        .inventory-menu nav {
        text-align: center;
        margin-bottom: 2rem;
    }

    .inventory-menu nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .inventory-menu nav ul li {
        display: inline-block;
        margin-right: 20px;
    }

    .inventory-menu nav ul li a {
        color: var(--primary-color);
        text-decoration: none;
        font-weight: 400;
        padding: 5px 10px;
        transition: border-color 0.3s ease, color 0.3s ease;
        border-bottom: 1px solid transparent; 
}

    .inventory-menu nav ul li a:hover,
    .inventory-menu nav ul li a:focus {
        color: whitesmoke;
        border-color: var(--secondary-color);
    }



</style>