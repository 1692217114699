
<template>
    <div class="welcome-message">
      <h1>Agrarbund Net</h1>
    </div>
  </template>
  
  <style scoped>
    .welcome-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 2rem;
    }

    h1, span {
        margin: 0; 
        font-weight: 475; /* Lighter font weight for headings */
        font-size: xx-large;
    }

  
  </style>