<template>
    <div class="admin-settings">
      <section class="add-note">
        <h2>Add a Note</h2>
        <label>
          Inbox: Owner
          <input type="text" v-model="settings.inbox.owner" />
        </label>
        <label>
          Inbox: Utilizer
          <input type="text" v-model="settings.inbox.utilizer" />
        </label>
        <label>
          Inbox: Machine Type
          <select v-model="settings.inbox.machineType">
            <option disabled value="">Please select one</option>
            <option>ionet</option>
            <option>kuzco</option>
          </select>
        </label>
        <button @click="submitNote">Submit</button>
      </section>
  
      <section class="identity-provider">
        <h2>Identity Provider</h2>
        <button @click="addProvider('gmail')">Add Gmail</button>
        <button @click="addProvider('twitter')">Add Twitter</button>
      </section>
    </div>
  </template>
  

<script setup>
import { reactive } from 'vue';

const settings = reactive({
  inbox: {
    owner: '', // Initially empty or set a default value
    utilizer: '', // Initially empty or set a default value
    machineType: ''
  },
  providers: []
});


function submitNote() {
  // Handle the submission logic here.
  // For example, sending this data to an API or processing it somehow.
  console.log('Submitting note settings:', settings.inbox);
}

function addProvider(provider) {
  settings.providers.push(provider);
  // You can also handle further logic here, like API calls to add identity providers.
  console.log('Added provider:', provider);
}
</script>
