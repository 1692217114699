<template>
    <svg  class="my-svg" 
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16">
          <path d="M2.45 6.76h9.59c.12 0 .23.05.32.14l1.52 1.56c.28.29.08.78-.32.78H3.97c-.12 0-.23-.05-.32-.14L2.13 7.54c-.28-.29-.08-.78.32-.78m-.32-2.07l1.52-1.56c.08-.09.2-.14.32-.14h9.58c.4 0 .6.49.32.78l-1.51 1.56c-.08.09-.2.14-.32.14H2.45c-.4 0-.6-.49-.32-.78m11.74 6.61l-1.52 1.56c-.09.09-.2.14-.32.14H2.45c-.4 0-.6-.49-.32-.78l1.52-1.56c.08-.09.2-.14.32-.14h9.58c.4 0 .6.49.32.78" /></svg>
  </template>
       
  <style scoped>
    .my-svg {
      width: 22px; /* Control size */
      height: 22px; /* Control size */
      fill: whitesmoke; /* Control color */
    }
  </style>
  


