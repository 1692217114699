<template>
    <div class="gradient-background">
        <Login
            v-if="!loginState" 
            @login="login"/>
        <div v-else>
            <header>
                <Header
                    :email="email"
                    :nickname="nickname" 
                    @logout="logout"/>
            </header>
            <main>
                <Welcome class="welcome" />
                
                <Navigation @navigationClicked="handleNavigationClick" class="menu"/>
                
                <Inventory 
                    v-if="currentNav === 'inventory' && !showCard" 
                    :InventoryItems="InventoryItems" 
                    @asset-clicked="handleAssetClick" />
                <Card 
                    v-else-if="currentNav === 'inventory' && showCard" 
                    :CurrentSelectedItem="CurrentSelectedItem" 
                    @goBack="handleCloseCard"/>
                <Admin  v-else-if="currentNav === 'admin'" />
                
            </main>
        </div>
    </div>
</template>

  
<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';

    import Header from './components/Header.vue';
    import Welcome from './components/Welcome.vue';
    import Login from './components/Login.vue';
    import Navigation from './components/Navigation.vue';
    import Inventory from './components/Inventory.vue';
    import Card from './components/Card.vue';
    import Admin from './components/Admin.vue';

    const loginState = ref(false);
    const email = ref('');
    const nickname = ref('');


    // State variable to keep track of the current navigation item
    const currentNav = ref('inventory');

    // State variable to keep track of the current selected item from the assets
    const CurrentSelectedItem = ref(null);

    // State variable to indicate whether the Card component should be shown
    const showCard = ref(false);

    // State variable to store the inventory items
    const InventoryItems = ref([]); // Initialize as an empty array

    const fetchInventoryItems = async () => {
      //axios.get(`/sqlagent?in_message=${sql}`)
      console.log('Fetching inventory items...');
      axios.get(`/echo`)
        .then(response => {console.log(response.data); 
          InventoryItems.value = response.data;})
    };

    
    const handleCloseCard = () => {
      console.log('Close card clicked');  
      showCard.value = false;
    };

    const login = () => {
      console.log('user logged in');  
      loginState.value = true;
    };

    const logout = () => {
      console.log('user logged out');  
      loginState.value = false;
    };

    const handleAssetClick = (item) => {
        console.log('Asset clicked', item);
        showCard.value = true;
        CurrentSelectedItem.value = item;
    };


    // Handler for the navigation click event
    const handleNavigationClick = (navItem) => {
        console.log(`Navigation item clicked: ${navItem}`);
        // Update the currentNav state with the clicked navigation item
        currentNav.value = navItem;
    };

    

    const con_structor = () => {
    console.log('starting up vuejs frontend...');
    
    const landingState = import.meta.env.VITE_LANDING || 'must login';

    if (landingState==="start") {   
        console.log('starting up vuejs frontend...');
        loginState.value = true;
    } else {
            axios.get("/whoami")
            .then(response => {
                console.log("running whoami...");
                console.log(response.data);
                if (response.data.email && response.data.email !== 'default@email.com') {
                    loginState.value = true;
                    email.value = response.data.email;
                    nickname.value = response.data.nickname;
                }
            });
        }
    };



    //todo: we can organize it so that the constroctor 
    // is organizing the order of all on MOunted functions
    onMounted(con_structor);
    
    onMounted(fetchInventoryItems);



    
</script>
  
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

:root {
  --background-color: #0b1a2e; /* Dark navy blue background */
  --primary-color: #1e88e5; /* Vibrant blue for highlights */
  --secondary-color: #ffd600; /* Bold yellow for additional accents */
  --text-color: #ffffff; /* White text */
  --border-color: #1d3557; /* Slightly lighter navy blue for borders and lines */
  --section-header-color: #8fa7c1; /* Light blue for section headers */
  --normal-text-font: 'Montserrat', sans-serif; /* Font for normal text */
  --section-header-font: 'Montserrat', sans-serif; /* Font for section headers */

}


body, html {
    margin: 0;
    padding: 0;
    font-family: var(--normal-text-font);
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
}

header {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    border-bottom: 2px solid var(--border-color); /* Thicker border for a more robust look */
}

.welcome {
    margin-bottom: 20px; /* Adjust the margin value as needed */
}

.menu {
    margin-bottom: 20px; /* Adjust the margin value as needed */
}

.gradient-background {
    min-height: 100vh;
    background-image: radial-gradient(circle at 50% 33%, #132b4d, #0B1A2E 60%); /* Add circular gradient */
}

/* Enhancing responsiveness */
@media (max-width: 600px) {
header {
        justify-content: center;
    }
}
</style>