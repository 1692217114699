import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'


// Create Vue application instance
const app = createApp(App);

// Enable Vue Devtools
app.config.devtools = true;

// Mount the app to the DOM
app.mount('#app');