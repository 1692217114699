<template>
    <table>
      <thead>
        <tr>
          <th>Asset (Note)</th>
          <th>Owner</th>
          <th>Utilizer</th>
          <th>Status</th>
          <th>Identity</th>
          <th>Wallet</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in InventoryItems" :key="index">
          <td @click="onAssetClick(item)">
           
            <span class="asset-image">
              <AppleLogo v-if="item.vendor === 'apple'" :height="22" />
              <NvidiaLogo v-else-if="item.vendor === 'nvidia'" :height="22" />
              <AMDLogo v-else-if="item.vendor === 'amd'" :height="22" />
            </span>
            {{ item.asset }}
          </td>
          <td>{{ item.owner }}</td>
          <td>{{ item.utilizer }}</td>
          <td>
            <span :class="'status ' + item.status"></span>{{ item.statusText }}
          </td>
          <td>{{ item.identity }}</td>
          <td>
            <span class="wallet-image" @click="copyToClipboard(item.wallet)">
              <component :is="getWalletLogoComponent(item.chain)" />
            </span>
            <span v-if="item.isMapped" @click="copyToClipboard(item.wallet)">{{ item.chain }}</span>
            <span v-else class="not-mapped">not mapped</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <span>Page 1</span> | <a href="#">Page 2</a>
    </div>
    <div v-if="showPopup" class="popup">
      Wallet address copied to clipboard!
    </div>
</template>
  
<script setup>
  import { ref } from 'vue';
  import { defineProps } from 'vue';

  import AppleLogo from './icons/AppleLogo.vue';
  import NvidiaLogo from './icons/NvidiaLogo.vue';
  import AMDLogo from './icons/AMDLogo.vue';
  import SolanaLogo from './icons/SolanaLogo.vue';
  import EthereumLogo from './icons/EthereumLogo.vue';

  // getting the props from the parent component
  const props = defineProps({
      InventoryItems: Array
  });

  const emit = defineEmits(['asset-clicked']);

  const showPopup = ref(false);

  const onAssetClick = (item) => {
      emit('asset-clicked', item);
      console.log('Asset clicked:', item);
      // Perform any action you want when an asset name is clicked
      // For example, showing more details about the item
      };


  const getWalletLogoComponent = (chain) => {
    switch (chain) {
      case 'sol':
        return SolanaLogo;
      case 'eth':
        return EthereumLogo;
      default:
        return null;
    }
  };

  const copyToClipboard = (walletAddress) => {
  console.log('Copying wallet address to clipboard:', walletAddress);

  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(walletAddress).then(() => {
      showPopup.value = true;
      setTimeout(() => {
        showPopup.value = false;
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy wallet address', err);
      fallbackCopyToClipboard(walletAddress);
    });
  } else {
    fallbackCopyToClipboard(walletAddress);
  }
};

const fallbackCopyToClipboard = (walletAddress) => {
  const textarea = document.createElement('textarea');
  textarea.value = walletAddress;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  showPopup.value = true;
  setTimeout(() => {
    showPopup.value = false;
  }, 2000);
};
  
</script>
  
<style scoped>
    table {
    width: 80%;
    margin: 20px auto;
    border-collapse: collapse;
    }

    table td {
    height: 20px; /* Example height, adjust as needed */
    }

    table, th, td {
    border: 1px solid var(--border-color);
    padding: 8px;
    text-align: left;
    font-weight: 300; /* Lighter font weight for table content */
    }

    thead {
    background-color: var(--primary-color);
    color: var(--background-color);
    font-weight: 400; /* Slightly bolder for header row for better readability */
    }

    tbody tr:nth-child(even) {
    background-color: #12325B;
    }

    tbody tr:hover td {
    border-bottom: 1px solid var(--secondary-color);
}





    td .status + span {
    white-space: nowrap; /* Prevents line wrapping */
    }

    .asset-image, .wallet-image {
    max-height: 100%; /* Adjusts to cell height */
    width: auto; /* Maintains aspect ratio */
    vertical-align: middle; /* Aligns image vertically in the middle */
    margin-right: 10px; /* Adds some space between the image and adjacent text */
    margin-top: -2px; /* Adjusts vertical alignment */
    }

    .pagination {
    text-align: left; /* Aligning pagination to the left */
    margin: 20px auto;
    width: 80%; /* Matching the table width */
    font-weight: 400; /* Consistent font weight for pagination */
    }

    .pagination a, .pagination span {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.0rem;
    padding: 5px;
    }

    .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    }

    .available {
    background-color: #00FF00; /* Green */
    box-shadow: 0 0 5px #00FF00;
    }

    .in-use {
    background-color: #FF0000; /* Red */
    box-shadow: 0 0 5px #FF0000;
    }

    .offline {
    background-color: gray; /* Yellow */
    box-shadow: 0 0 5px gray;
    }

    .not-mapped {
    font-weight: bold;
    color: var(--secondary-color); /* Set the font color to red for the "not mapped" case */
    }

    .popup {
        position: fixed;
        top: 20px;
        right: 20px;
        background-color: var(--primary-color);
        color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        animation: fadeInOut 3s ease-in-out;
    }

    @keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        10% {
            opacity: 1;
            transform: translateY(0);
        }
        90% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(-20px);
        }
    }

</style>