<template>
  <!-- Container for logo, asset, status, and back button -->
  <div class="section-card">
    <div class="header">
      <!-- Logo component aligned to the left -->

      <span class="asset-image">
        <AppleLogo v-if="CurrentSelectedItem.vendor === 'apple'" :height="42" />
        <NvidiaLogo v-else-if="CurrentSelectedItem.vendor === 'nvidia'" :height="42" />
        <AMDLogo v-else-if="CurrentSelectedItem.vendor === 'amd'" :height="42" />
      </span>
      <!-- Asset and Status wrapped in a container -->
      <div class="asset-info">
        <div class="asset-text">{{ CurrentSelectedItem.asset }}</div>
        <div class="asset-status">{{ CurrentSelectedItem.status }}</div>
      </div>
      <!-- Back button aligned to the right -->
      <!--<button class="back-button" @click="goBack">Back</button>-->
      <div class="back-button">
        <a href="#inventory" @click.prevent="emitClick('goBack')">Back</a>
      </div>
    </div>
  </div>
  <div class="section-card">
    <div class= "card-content">
    <h3>Details</h3>
    <table>
      <tr>
        <td>Identity:</td>
        <td>{{ CurrentSelectedItem.identity }}</td>
      </tr>
      <tr>
        <td>Utilizer:</td>
        <td>{{ CurrentSelectedItem.utilizer }}</td>
      </tr>
      <tr>
        <td>Utilization Period:</td>
        <td>2024-03-01 to 2024-03-15</td>
      </tr>
      <tr>
        <td>Wallet Address:</td>
        <td>{{ CurrentSelectedItem.wallet }}</td>
      </tr>
    </table>
    </div>
  </div>
  <div class="section-card">
    
    <h3>History</h3>
    <table>
      <thead>
        <tr>
          <th>Duration (days:hours)</th>
          <th>Start (date, time)</th>
          <th>End (date, time)</th>
          <th>Utilizer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>5:12</td>
          <td>2023-04-01, 10:00</td>
          <td>2023-04-06, 22:00</td>
          <td>io.net</td>
        </tr>
        <tr>
          <td>3:8</td>
          <td>2023-03-15, 14:30</td>
          <td>2023-03-18, 22:30</td>
          <td>kuszco</td>
        </tr>
        <tr>
          <td>5:12</td>
          <td>2023-04-01, 10:00</td>
          <td>2023-04-06, 22:00</td>
          <td>io.net</td>
        </tr>
        <tr>
          <td>3:8</td>
          <td>2023-03-15, 14:30</td>
          <td>2023-03-18, 22:30</td>
          <td>kuszco</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section-card">
    <h3>CL Interface</h3>
    <div class="cli-shell">
      <div class="cli-body">
        <div class="cli-line">
          <pre>
          .-^-.      __
      .-'   _   '-. |. |
    /      |_|     \| .|
   /        .       \  |
  /|_______________ |\ |
    |    |=|=|=|    |  |
    |    |--|--|    |  |
    |    |=|=|=|    |  |
^^^^^^^^^^^^^^^^^^^^^^^^
Agrarbund Shell (c) 2024
        </pre>

          <span class="cli-prompt">></span>
          <span class="cli-command">
            access -u {{ CurrentSelectedItem.utilizer }} -a
            {{ CurrentSelectedItem.asset }}
          </span>
        </div>
        <div class="cli-line">
          <span class="cli-prompt">></span>
          <span class="cli-cursor"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

import AppleLogo from './icons/AppleLogo.vue'
import NvidiaLogo from './icons/NvidiaLogo.vue'
import AMDLogo from './icons/AMDLogo.vue'

const emit = defineEmits(['goBack']);
const props = defineProps({
  CurrentSelectedItem: Object,
})

const getLogoComponent = (vendor) => {
  switch (vendor) {
    case 'apple':
      return AppleLogo
    case 'nvidia':
      return NvidiaLogo
    case 'amd':
      return AMDLogo
    default:
      return null
  }
}

const emitClick = () => {
  // Add your logic to handle the back button click
  console.log('Back button clicked')
  emit('goBack');
}
</script>

<style scoped>

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: auto;
  margin-top: auto
}

.card-content{
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: 24px;

}

.asset-info {
  display: flex;
  flex-direction: column;
  margin-left: 26px;
}

.asset-text {
  font-size: 1.5em;
}

.asset-status {
  font-size: 1em;
  color: #8fa7c1;
}

.logo,
.header>div {
  margin-right: 12px;
}

.back-button {
  margin-left: auto;
}

.back-button a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 400;
  padding: 5px 10px;
  transition:
    border-color 0.3s ease,
    color 0.3s ease;
  border-bottom: 1px solid transparent;
}

.back-button a:hover,
.back-button a:focus {
  color: whitesmoke;
  border-color: var(--secondary-color);
}

.section-card {
  color: var(--text-color);
  background-color: #12325b;
  width: 60%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: var(--normal-text-font);
  border: 2px solid var(--border-color);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* This centers the content vertically */
  height: 100%;
  /* You might adjust this based on your needs */
  min-height: 40px;
  /* Adjust as needed */
}

.section-card h3 {
  font-family: var(--section-header-font);
  font-weight: 200;
  font-size: larger;
  /*margin-bottom: 10px;*/
}

table {

  border-collapse: collapse;
}

table td {
  height: 12px;
  /* Example height, adjust as needed */
}

table,
th,
td {
  text-align: left;
  font-weight: 300;
  /* Lighter font weight for table content */
}

thead {
  color: #8fa7c1;
  font-weight: 400;
  /* Slightly bolder for header row for better readability */
}

tbody tr:nth-child(even) {
  background-color: #12325B;
}

tbody tr:hover td {
  border-bottom: 1px solid var(--secondary-color);
}




.cli-shell {
  background-color: #0a0a0a;
  border-radius: 5px;
  padding: 20px;
  font-family: 'Courier New', monospace;
  color: #00ff00;
  height: 300px;
  overflow-y: auto;
}

.cli-header {
  margin-bottom: 10px;
}

.cli-title {
  font-size: 1.2em;
  font-weight: bold;
}

.cli-body {
  display: flex;
  flex-direction: column;
}

.cli-line {
  margin-bottom: 5px;
}

.cli-prompt {
  color: #ffff00;
  margin-right: 5px;
}

.cli-command {
  color: #00ffff;
}

.cli-output {
  color: #00ff00;
}

.cli-cursor {
  display: inline-block;
  width: 8px;
  height: 1em;
  background-color: #00ff00;
  animation: blink 1s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
</style>